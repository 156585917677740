var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9iXavK3eTEVBFLAevZKpn"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import config from 'config';
import { isDevEnvironment } from 'utils/isDevEnvironment';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if ((config.environment === 'prod' || config.environment === 'uat') && !isDevEnvironment())
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://a81bdb445bfc4cd2a51504760647272a@o286171.ingest.sentry.io/4504954727366656',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.0,
    environment: config.environment,
    ignoreErrors: [
      'Invariant: attempted to hard navigate to the same URL',
      'Non-Error promise rejection captured',
      'TypeError: null is not an object (evaluating \'d[s]\')',
      /^s\(webkit-masked-url:\/\/hidden\//,
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
